// import Elk, { ElkNode, ElkPrimitiveEdge } from "elkjs"
import { Elements, isEdge, isNode, Node, Position } from "react-flow-renderer"
import dagre from "dagre"

const DEFAULT_WIDTH = 176
const DEFAULT_HEIGHT = 36

export const nodeStrokeColor = (n: Node): string => {
    if (n.style?.background) return n.style.background.toString()
    if (n.type === 'input') return '#0041d0'
    if (n.type === 'output') return '#ff0072'
    if (n.type === 'default') return '#1a192b'

    return '#eee'
}

export const nodeColor = (n: Node): string => {
    if (n.style?.background) return n.style.background.toString()

    return '#fff'
}

export const createGraphLayoutDagre = (
    elements: Elements,
    nodeStates: Node[],
    isHorizontal: boolean): Elements => {
    const dagreGraph = new dagre.graphlib.Graph()
    dagreGraph.setDefaultEdgeLabel(() => ({}))

    const direction = isHorizontal ? 'LR' :'TB'
    dagreGraph.setGraph({ rankdir: direction })

    const getWidth = (width: number = DEFAULT_WIDTH): number => {
        return isHorizontal ? width : width + 150
    }

    const getHeight = (height: number = DEFAULT_HEIGHT): number => {
        return isHorizontal ? height + 50 : height
    }

    nodeStates.forEach(state => {
        dagreGraph.setNode(state.id, {
            width: getWidth(state.__rf?.width),
            height: getHeight(state.__rf?.height)
        })
    })

    elements.forEach(el => {
        if (isEdge(el)) dagreGraph.setEdge(el.source, el.target)
    })

    dagre.layout(dagreGraph)

    return elements.map((el) => {
        if (isNode(el)) {
            const node = dagreGraph.node(el.id)
            el.targetPosition = isHorizontal ? Position.Left : Position.Top
            el.sourcePosition = isHorizontal ? Position.Right : Position.Bottom

            el.position = {
                x: node.x - node.width / 2 + Math.random() / 1000,
                y: node.y - node.height / 2
            }
        }

        return el
    })
}

// export const createGraphLayoutElk = async (elements: Elements): Promise<Elements> => {
//     const nodes: ElkNode[] = []
//     const edges: ElkPrimitiveEdge[] = []

//     const elk = new Elk({
//         defaultLayoutOptions: {
//             'elk.algorithm': 'layered',
//             'elk.direction': 'RIGHT',
//             'elk.spacing.nodeNode': '75',
//             'elk.layered.spacing.nodeNodeBetweenLayers': '75'
//         }
//     })

//     elements.forEach((el) => {
//         if (isNode(el)) {
//             nodes.push({
//                 id: el.id,
//                 width: el.__rf?.width ?? DEFAULT_WIDTH,
//                 height: el.__rf?.height ?? DEFAULT_HEIGHT
//             })
//         } else {
//             edges.push({
//                 id: el.id,
//                 target: el.target,
//                 source: el.source
//             })
//         }
//     })

//     const newGraph = await elk.layout({
//         id: 'root',
//         children: nodes,
//         edges: edges
//     })

//     return elements.map((el) => {
//         if (isNode(el)) {
//             const node = newGraph?.children?.find((n) => n.id === el.id)
//             if (node?.x && node?.y && node?.width && node?.height) {
//                 el.position = {
//                     x: node.x - node.width / 2 + Math.random() / 1000,
//                     y: node.y - node.height / 2
//                 }
//             }
//         }

//         return el
//     })
// }