import { ReactFlowProvider } from 'react-flow-renderer'
import { SchemaViewPage } from './SchemaViewPage'

interface Props {

}

const Dashbard = (props: Props) => {
    return (
        <ReactFlowProvider>
            <SchemaViewPage />
        </ReactFlowProvider>
    )
}

export default Dashbard
