import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { AttachmentIcon, CopyIcon, InfoIcon, LinkIcon, SearchIcon } from '@chakra-ui/icons'
import {
    Button,
    ButtonGroup,
    IconButton,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverTrigger,
    PopoverHeader,
    PopoverFooter,
    Stack,
    Checkbox,
} from '@chakra-ui/react'
import ReactFlow, {
    addEdge,
    ArrowHeadType,
    Background,
    Connection,
    ControlButton,
    Controls,
    Edge,
    EdgeTypesType,
    Elements,
    isEdge,
    MiniMap,
    Node,
    OnLoadParams,
    removeElements,
    useStoreState,
} from 'react-flow-renderer'
import yaml from 'js-yaml'
import { DropEvent, useDropzone } from 'react-dropzone'
import { ColorModeSwitcher } from '../../components'
import { useStore } from '../../store'
import { FlowElementProps, OnElementActionHandler, SchemaComponentProps, SchemaConnectionProps } from '../../types'
import { sampleSchema } from '../../types/schemaModel'
import { ScenarioFilter } from './ScenarioFilter'
import { createGraphLayoutDagre, nodeColor, nodeStrokeColor } from './utils'
import { ButtonEdge } from '../../components/react_flow/ButtonEdge'

const componentToNode = (
    component: SchemaComponentProps,
    onAction: OnElementActionHandler
): Node<FlowElementProps> => {
    return {
        id: component.id,
        type: component.type,
        data: {
            onAction: onAction,
            label: (
                <>
                    <strong>{component.name}</strong>
                </>
            ),
        },
        position: { x: 100, y: 100 },
    }
}

const connectionToEdge = (
    connection: SchemaConnectionProps,
    onAction: OnElementActionHandler
): Edge<FlowElementProps> => {
    return {
        id: connection.id,
        source: connection.source,
        target: connection.target,
        arrowHeadType: ArrowHeadType.Arrow,
        type: 'buttonedge',
        data: {
            onAction: onAction,
            label: connection.scenarios?.length || 0,
            scenarios: connection.scenarios || []
        }
        // animated: animated,
    }
}

const scenariosPopoverContent = (
    scenarios: string[],
    isChecked: (scenario: string) => boolean,
    onChange: (scenario: string, selected: boolean) => void,
    onClear: () => void
): ReactNode => {
    // const checked: Record<string, boolean> = scenarios.reduce((acc, e) => ({...acc, e: isChecked(e)}), {})

    return <>
        <PopoverHeader fontWeight="semibold">Scenarios</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
            <Stack>
                {
                    scenarios.map(scenario =>
                        <Checkbox
                            key={scenario}
                            isChecked={isChecked(scenario)}
                            onChange={(e) => {
                                onChange(scenario, e.target.checked)
                            }}>
                            {scenario}
                        </Checkbox>)
                }
            </Stack>
        </PopoverBody>
        <PopoverFooter d="flex" justifyContent="flex-end">
            <ButtonGroup size="sm">
                <Button onClick={(evt) => onClear()} colorScheme="red">Clear</Button>
            </ButtonGroup>
        </PopoverFooter>
    </>
}

interface PopoverStateProps {
    isOpen: boolean
    x: number
    y: number
    content: ReactNode
}

export const SchemaViewPage = () => {
    // eslint-disable-next-line
    const rfRef = useRef<HTMLDivElement>(null)
    const [rfInstance, setRfInstance] = useState<OnLoadParams | null>(null)
    const [showMiniMap, setShowMiniMap] = useState<boolean>(false)
    const [elements, setElements] = useState<Elements<FlowElementProps>>([])
    const [isHorizontalAutoLayout, setIsHorizontalAutoLayout] = useState(false)
    const [popoverState, setPopoverState] = useState<PopoverStateProps>({ isOpen: false, x: 0, y: 0, content: null })
    const nodeStates = useStoreState(state => state.nodes)
    const onElementsRemove = (elementsToRemove: Elements) => setElements((els) => removeElements(elementsToRemove, els))
    const onConnect = (params: Edge | Connection) => setElements((els) => addEdge(params, els))
    const onLoad = (reactFlowInstance: OnLoadParams) => {
        setRfInstance(reactFlowInstance)
        reactFlowInstance.fitView()
    }

    const {
        loadSchema,
        allComponentsAndConnections,
        addSelectedScenario,
        deleteSelectedScenario,
        scenarioSelected,
        selectedScenarios,
        scenarioFilterProps,
        updateScenarioFilterProps
    } = useStore()

    const onFileSelected = useCallback(async (files: File[], _event: DropEvent) => {
        if (files.length === 0) return

        const file: File = files[0]
        const reader = new FileReader()

        reader.onload = async (e) => {
            try {
                const fileContent = e.target?.result?.toString() || ""
                const yamlDoc = yaml.load(fileContent)
                loadSchema(yamlDoc)
            } catch (error) {
            }
        }

        reader.readAsText(file)
    }, [loadSchema])

    const { getRootProps, getInputProps, open: openSchemaFile } = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        onDropAccepted: onFileSelected
    })

    const closePopover = (): void => {
        setPopoverState(state => ({ ...state, isOpen: false }))
    }

    const onElementAction = useCallback(
        (item: SchemaComponentProps | SchemaConnectionProps, action: string, params: any = {}) => {
            if (action !== "ShowScenarios") return

            const edge = item as SchemaConnectionProps

            if (!edge.source || !params.x || !params.y) return

            const onChangeHandler = (scenario: string, checked: boolean) => {
                checked ? addSelectedScenario(scenario) : deleteSelectedScenario(scenario)
                closePopover()
            }

            const onClearHandler = () => {
                edge.scenarios.forEach(e => deleteSelectedScenario(e))
                closePopover()
            }

            const popoverContent = scenariosPopoverContent(
                edge.scenarios,
                scenarioSelected,
                onChangeHandler,
                onClearHandler)

            setPopoverState({
                isOpen: true,
                x: params.x,
                y: params.y,
                content: popoverContent
            })
        }, [scenarioSelected, addSelectedScenario, deleteSelectedScenario])

    const anyScenarioActive = useCallback(
        (scenarios: string[]): boolean => {
            return scenarioSelected('__All_Scenarios') ||
                scenarios.some(scenario => scenarioSelected(scenario))
        }, [scenarioSelected])

    const onAutoLayout = useCallback(
        () => {
            const layoutElements = createGraphLayoutDagre(elements, nodeStates, isHorizontalAutoLayout)
            setIsHorizontalAutoLayout(!isHorizontalAutoLayout)
            setElements(layoutElements)
            rfInstance?.fitView()
        },
        [elements, nodeStates, isHorizontalAutoLayout, rfInstance])

    useEffect(() => {
        loadSchema(sampleSchema)
        return () => { }
    }, [loadSchema])

    useEffect(() => {
        const elements: Elements = []

        Object.values(allComponentsAndConnections).forEach(item => {
            const onAction: OnElementActionHandler = (action: string, params?: any) => {
                onElementAction(item, action, params)
            }

            if ((item as SchemaComponentProps).name) {
                elements.push(componentToNode(item as SchemaComponentProps, onAction))
            } else {
                elements.push(connectionToEdge(item as SchemaConnectionProps, onAction))
            }
        })

        setElements(elements)

        return () => { }
    }, [allComponentsAndConnections, onElementAction])

    useEffect(() => {
        setElements(els => els.map(el => {
            if (isEdge(el)) {
                const scenarios: string[] = el.data?.scenarios || []
                const animated = anyScenarioActive(scenarios)
                el.animated = animated
            }

            return el
        }))

        return () => { }
    }, [setElements, selectedScenarios, anyScenarioActive])

    const toggleScenarioFilter = useCallback(
        () => updateScenarioFilterProps({ minimized: !scenarioFilterProps.minimized })
        , [updateScenarioFilterProps, scenarioFilterProps])

    const toggleMiniMap = useCallback(
        () => setShowMiniMap(!showMiniMap)
        , [showMiniMap])

    const edgeTypes: EdgeTypesType = {
        buttonedge: ButtonEdge,
    }

    return (
        <ReactFlow
            ref={rfRef}
            elements={elements}
            onElementsRemove={onElementsRemove}
            onConnect={onConnect}
            onLoad={onLoad}
            snapToGrid={true}
            snapGrid={[15, 15]}
            edgeTypes={edgeTypes}
        >
            {showMiniMap && <MiniMap
                nodeStrokeColor={nodeStrokeColor}
                nodeColor={nodeColor}
                nodeBorderRadius={2}
            />}
            <Controls>
                <ControlButton onClick={toggleMiniMap}>
                    <CopyIcon color="black" />
                </ControlButton>
            </Controls>
            <Background color="#aaa" gap={16} />
            <div
                style={{
                    position: 'absolute',
                    left: 10,
                    top: 10,
                    zIndex: 4,
                    textTransform: 'none',
                }}
                {...getRootProps({ className: 'dropzone' })}
            >
                <input {...getInputProps()} />
                {!scenarioFilterProps.minimized && <ScenarioFilter />}
                <Stack direction="row" spacing={2}>
                    <Button
                        leftIcon={<AttachmentIcon />}
                        size="sm"
                        onClick={openSchemaFile}
                        colorScheme="gray"
                        variant="outline">
                        Open
                    </Button>
                    <IconButton
                        size="sm"
                        colorScheme="gray"
                        variant="outline"
                        onClick={toggleScenarioFilter}
                        aria-label="Search objects"
                        icon={<SearchIcon />}
                    />
                    <IconButton
                        size="sm"
                        colorScheme="gray"
                        variant="outline"
                        onClick={onAutoLayout}
                        aria-label="Auto Layout"
                        icon={<LinkIcon />}
                    />
                </Stack>
            </div>
            <div
                style={{
                    position: 'absolute',
                    right: 10,
                    top: 10,
                    zIndex: 4,
                    textTransform: 'none',
                }}
            >
                <Stack direction="row" spacing={0}>
                    <ColorModeSwitcher />
                    <IconButton
                        size="sm"
                        colorScheme="blue"
                        variant="ghost"
                        aria-label="Get help"
                        icon={<InfoIcon />}
                    />
                </Stack>
            </div>
            <div>
                <Popover
                    returnFocusOnClose={false}
                    isOpen={popoverState.isOpen}
                    onClose={closePopover}
                    placement="auto"
                    closeOnBlur={true}
                >
                    <PopoverTrigger>
                        <Button
                            height={0}
                            width={0}
                            left={popoverState.x}
                            top={popoverState.y}
                            colorScheme="pink" />
                    </PopoverTrigger>
                    <PopoverContent>
                        {popoverState.content}
                    </PopoverContent>
                </Popover>
            </div>
        </ReactFlow>
    )
}
