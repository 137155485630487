import { useCallback, useEffect, useState } from 'react'
import { Box, Button, HStack, Spacer, Flex, LightMode } from '@chakra-ui/react'
import { Rnd } from 'react-rnd'
import Select, { ActionMeta, Props as SelectProps } from 'react-select'
import makeAnimated from 'react-select/animated'
import { useStore } from '../../store'

interface ScenarioFilterProps {
}

type ScenarioType = { label: string, value: string }

const animatedComponents = makeAnimated()

export const ScenarioFilter = (props: ScenarioFilterProps) => {
    const [options, setOptions] = useState<SelectProps["options"]>([])
    const [value, setValue] = useState<SelectProps["options"]>([])
    const {
        scenarios,
        selectedScenarios,
        addSelectedScenario,
        deleteSelectedScenario,
        clearSelectedScenarios,
        scenarioFilterProps,
        updateScenarioFilterProps
    } = useStore()

    useEffect(() => {
        setOptions(Array.from(scenarios.values())
            .map(scenario => scenario.toString())
            .map(scenario => ({ value: scenario, label: scenario })))

        return () => { }
    }, [scenarios])

    useEffect(() => {
        setValue(Array.from(selectedScenarios.values())
            .map(scenario => scenario.toString())
            .map(scenario => ({ value: scenario, label: scenario })))

        return () => { }
    }, [selectedScenarios])

    const minimize = useCallback(
        () => updateScenarioFilterProps({ minimized: true })
        , [updateScenarioFilterProps])

    const clear = () => clearSelectedScenarios()

    const onChange = useCallback(
        (action: ActionMeta<ScenarioType>) => {
            switch (action.action) {
                case "select-option":
                    action.option?.value && addSelectedScenario(action.option.value)
                    break;
                case "remove-value":
                case "pop-value":
                    action.removedValue?.value && deleteSelectedScenario(action.removedValue?.value)
                    break;
                default:
                    break;
            }
        }, [addSelectedScenario, deleteSelectedScenario])

    return (
        <Rnd
            minHeight="100px"
            minWidth="250px"
            size={{ width: scenarioFilterProps.width, height: "auto" }}
            position={{ x: scenarioFilterProps.x, y: scenarioFilterProps.y }}
            onDragStop={(_e, d) => {
                updateScenarioFilterProps({ x: d.x, y: d.y })
            }}
            onResizeStop={(_e, _direction, ref, _delta, position) => {
                updateScenarioFilterProps({
                    width: ref.style.width,
                    height: ref.style.height,
                    ...position
                })
            }}
        >
            <Box
                backgroundColor="white"
                color="black"
                p={2}
                borderWidth={2}
                borderRadius="md"
                shadow="md"
                width="full"
                height="100%"
                borderColor="gray.300">
                <Flex width="full" height="100%" direction="column">
                    <Select
                        isMulti
                        isSearchable
                        closeMenuOnSelect={false}
                        isClearable={false}
                        options={options}
                        components={animatedComponents}
                        value={value}
                        onChange={(e, a) => onChange(a as ActionMeta<ScenarioType>)}
                        onBlur={e => e.preventDefault()}
                        placeholder="Select scenarios..."
                    />
                    <Spacer />
                    <HStack pt={2}>
                        <Spacer />
                        <LightMode>
                            <Button size="sm" colorScheme="gray" variant="outline" onClick={minimize}>Minimize</Button>
                            <Button size="sm" colorScheme="gray" variant="outline" onClick={clear}>Clear</Button>
                        </LightMode>
                    </HStack>
                </Flex>
            </Box>
        </Rnd>
    )
}
