type ComponentId = string

interface SchemaModelConnection {
    component: ComponentId
    scenarios: string[]
}

interface SchemaModelComponent {
    id: ComponentId
    name: string
    inputOnly?: boolean
    outputOnly?: boolean
    connectsTo?: SchemaModelConnection[]
    connectsFrom?: SchemaModelConnection[]
}

export interface SchemaModel {
    version: string
    components: SchemaModelComponent[]
}

export const sampleSchema: SchemaModel = {
    version: "0.0.1",
    components: [
        {
            id: "person",
            name: "Person",
            inputOnly: true,
            connectsTo: [
                {
                    component: "department",
                    scenarios: ["belongs to department"]
                }
            ]
        },
        {
            id: "department",
            name: "Department"
        },
        {
            id: "manager",
            name: "Manager",
            connectsFrom: [
                {
                    component: "product",
                    scenarios: ["product ownership"]
                }
            ]
        },
        {
            id: "product",
            name: "Product",
            connectsFrom: [
                {
                    component: "person",
                    scenarios: ["product ownership"]
                },
                {
                    component: "department",
                    scenarios: ["belongs to department", "some other"]
                }
            ]
        }
    ]
}
