import create from 'zustand'
import { SchemaConnectionProps, SchemaComponentProps, SchemaModel } from '../types'
import { createNodesAndEdges } from './utils'

interface AppState {
    schema?: SchemaModel
    allComponentsAndConnections: Record<string, SchemaComponentProps | SchemaConnectionProps>
    scenarios: Set<string>
    selectedScenarios: Set<string>
    scenarioFilterProps: {
        minimized: boolean,
        x: number,
        y: number,
        width: string,
        height: string
    },
    updateScenarioFilterProps: (data: any) => void
    loadSchema: (data: any) => void
    addSelectedScenario: (name: string) => void
    deleteSelectedScenario: (name: string) => void
    scenarioSelected: (name: string) => boolean
    clearSelectedScenarios: () => void
    addScenario: (name: string) => void
    deleteScenario: (name: string) => void
}

export const useStore = create<AppState>((set, get) => ({
    schema: undefined,
    allComponentsAndConnections: {},
    scenarios: new Set<string>(),
    selectedScenarios: new Set<string>(),
    scenarioFilterProps: {
        minimized: true,
        x: 50,
        y: 200,
        width: '300px',
        height: '100px',
    },
    updateScenarioFilterProps: (data: any) => {
        set(state => ({
            scenarioFilterProps: {
                ...state.scenarioFilterProps,
                ...data
            }
        }))
    },
    loadSchema: (data: any) => {
        const parsedData = data as SchemaModel
        const { nodes, edges, scenarios } = createNodesAndEdges(parsedData)
        const elements = { ...nodes, ...edges }

        set(() => ({
            schema: parsedData,
            allComponentsAndConnections: elements,
            scenarios: scenarios,
            selectedScenarios: new Set<string>()
        }))
    },
    addSelectedScenario: (name: string) => {
        set(state => ({ selectedScenarios: new Set(state.selectedScenarios.add(name)) }))
    },
    deleteSelectedScenario: (name: string) => {
        set(state => ({
            selectedScenarios: new Set(Array.from(state.selectedScenarios).filter(x => x !== name))
        }))
    },
    scenarioSelected: (name: string) => {
        return get().selectedScenarios.has(name)
    },
    clearSelectedScenarios: () => {
        set(state => ({
            selectedScenarios: new Set()
        }))
    },
    addScenario: (name: string) => {
        set(state => ({ scenarios: new Set(state.scenarios.add(name)) }))
    },
    deleteScenario: (name: string) => {
        set(state => ({
            scenarios: new Set(Array.from(state.scenarios).filter(x => x !== name))
        }))
    },
}))
