import { Route, Switch } from 'wouter'
import Dashboard from './dashboard/Index'
import { FluidBox } from '../components'
import TermsOfUsePage from './legal/TermsOfUsePage'

const MainLayout = () => {
    return (
        <FluidBox>
            <Switch>
                <Route path="/" component={Dashboard} />
                <Route>
                    <Dashboard />
                </Route>
            </Switch>
        </FluidBox>
    )
}

export const Routes = () => {
    return (
        <>
            <Switch>
                <Route path="/terms-of-use" component={TermsOfUsePage} />
                <Route path="/:rest*" component={MainLayout} />
            </Switch>
        </>
    )
}
