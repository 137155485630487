import { SchemaComponentProps, SchemaConnectionProps } from "../types"
import { SchemaModel } from "../types/schemaModel"

const AllScenarios = "__All_Scenarios"

export const createNodesAndEdges = (data: SchemaModel) => {
    const nodes: Record<string, SchemaComponentProps> = {}
    const edges: Record<string, SchemaConnectionProps> = {}
    const scenarios = new Set<string>([AllScenarios])

    data.components.forEach(component => {
        const type = component.inputOnly
            ? "input"
            : component.outputOnly
                ? "output" : "default"
        nodes[component.id] = {
            id: component.id,
            name: component.name,
            type: type
        }

        if (component.connectsFrom) {
            component.connectsFrom.forEach(connect => {
                if (!connect.component) return

                const { scenarios: connectedScenarios = [] } = connect
                const edgeId = `${component.id}<--${connect.component}`
                edges[edgeId] = {
                    id: edgeId,
                    source: connect.component,
                    target: component.id,
                    scenarios: connectedScenarios
                }

                connectedScenarios.forEach(s => scenarios.add(s))
            })
        }

        if (component.connectsTo) {
            component.connectsTo.forEach(connect => {
                if (!connect.component) return

                const { scenarios: connectedScenarios = [] } = connect
                const edgeId = `${component.id}-->${connect.component}`
                edges[edgeId] = {
                    id: edgeId,
                    source: component.id,
                    target: connect.component,
                    scenarios: connectedScenarios
                }

                connectedScenarios.forEach(s => scenarios.add(s))
            })
        }
    })

    return { nodes, edges, scenarios }
}