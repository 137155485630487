import {
    getBezierPath,
    getEdgeCenter,
    getMarkerEnd,
    WrapEdgeProps,
} from 'react-flow-renderer'
import { FlowElementProps } from '../../types';

const foreignObjectSize = 40

export const ButtonEdge = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    data,
    arrowHeadType,
    markerEndId,
}: WrapEdgeProps<FlowElementProps>) => {
    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    })

    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    })

    const onEdgeClick = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        evt.stopPropagation()
        data?.onAction("ShowScenarios", { x: evt.clientX, y: evt.clientY })
    }

    return (
        <>
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={edgeCenterX - foreignObjectSize / 2}
                y={edgeCenterY - foreignObjectSize / 2}
                className="edgebutton-foreignobject"
                requiredExtensions="http://www.w3.org/1999/xhtml"
            >
                <div className="foreignobject-body">
                    <button className="edgebutton" onClick={onEdgeClick}>
                        {data?.label}
                    </button>
                </div>
            </foreignObject>
        </>
    )
}
